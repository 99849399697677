import blackFridayTheme from '~/core/config/promo-theme/themes/black-friday.theme'
import defaultTheme from '~/core/config/promo-theme/themes/default.theme'
import winterTheme from '~/core/config/promo-theme/themes/winter.theme'

import { PromoTheme } from '~/core/config/promo-theme/types'

// Please keep the order of themes per priority
// The first will have the highest priority
// and will be applied in case a feature flag is enabled.
// Default theme should not be included here.
export const orderedThemes: PromoTheme[] = [
  winterTheme,
  blackFridayTheme,
]

export default defaultTheme
